/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
// No trailing slashes!
module.exports = {
    getSiteUrl: () => {
        const environment = process.env.GATSBY_ENV || process.env.NODE_ENV;
        switch (environment) {
            case 'production':
                return 'https://um22.esteco.com';
            case 'internal-staging':
                return 'https://builderweb.it.esteco.net';
            case 'netlify-staging':
                return 'https://5f0382f02b5750d495b014c2--tender-payne-742303.netlify.app';
            case 'development':
            default:
                return 'http://localhost:8000';
        }
    }
}
